<script lang="ts" setup>
import { ref, computed, watch, getCurrentInstance } from "vue";
import { useAuthStore } from "@/stores/auth";

const props = defineProps({
  partyReference: {
    type: String,
    default: "",
  },
});

const dialog = ref(false);
const partyLogoDropzone = ref(false);
const authStore = useAuthStore();
const logoUploadUrl = computed(() => (process.env.VUE_APP_PORTAL_URL ? process.env.VUE_APP_PORTAL_URL : "/") + "api/asset/logo/" + props.partyReference);

watch(
  () => dialog.value,
  () => {
    if (dialog.value == true) {
      setAuthorization();
    } else {
      // Zaken opschonen.
    }
  }
);

async function setAuthorization() {
  // Authorization header kan hier pas gezet worden als deze in de template zet gaat ie verloren in de init van dropzone
  const token = await authStore.getAccessToken();
  partyLogoDropzone.value.dropzone.options.headers = { Authorization: `Bearer ${token}` };
}

async function onCancel() {
  dialog.value = false;
}

//hack voor vue 2.7
const proxy = getCurrentInstance().proxy;

function onUploadError(file, message, xhr) {
  console.log("onUploadError, file, xhr: ", file, xhr);
  console.log("Message ====> " + JSON.stringify(message));
  proxy.$toaster.warning("Logo wijzigen mislukt (" + message + ").");
}
</script>

<template>
  <v-dialog v-model="dialog" scrollable persistent max-width="600">
    <template v-slot:activator="{ on, attrs }">
      <v-btn dark v-bind="attrs" v-on="on" class="primary">
        <v-icon dark left>mdi-update</v-icon>
        <span>Uploaden</span>
      </v-btn>
    </template>
    <!--<form id="adm-addParty-form" @submit.prevent="submit">-->
    <v-card class="mx-auto">
      <v-card-title>
        <header class="app-welcome-message">
          <h1>Logo uploaden</h1>
        </header>
        <v-spacer></v-spacer>
        <v-btn text @click="onCancel()">
          <v-icon dark>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <!-- Het is bewust form i.p.v. v-form omdat anders de form-validation on submit niet werkt en je deze zelf zal moeten implementeren. -->
        <v-container class="register-form">
          <v-row>
            <v-col cols="12">
              <div class="form-group">
                <label for="party-add-logo">Logo toevoegen</label>
                <vue-dropzone
                  id="party-logo-dropzone"
                  ref="partyLogoDropzone"
                  v-on:vdropzone-error="onUploadError"
                  :options="{
                    url: logoUploadUrl,
                    acceptedFiles: '.png,.jpg,.jpeg,.gif',
                    maxFilesize: 1,
                    maxFileSizeInMB: 3,
                    maxFiles: 1,
                    thumbnailHeight: 200,
                    addRemoveLinks: true,
                    dictDefaultMessage: 'Sleep uw bestand hier naar toe of klik om te uploaden. Toegestane formaten: .png, .jpg, .jpeg, .gif',
                    dictFileTooBig: 'Het bestand is te groot ({{filesize}}MiB). Maximale bestandsgrootte: {{maxFilesize}}MiB.',
                    dictInvalidFileType: 'Het is niet mogelijk bestanden van dit type te uploaden (toegestaan: .png, .jpg, .jpeg, .gif).',
                    dictResponseError: 'De Server reageerde met code {{statusCode}}.',
                    dictRemoveFile: 'Verwijder bestand',
                    dictMaxFilesExceeded: 'U kunt maximaal 1 bestand uploaden.',
                  }"
                ></vue-dropzone>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
    <!--</form>-->
  </v-dialog>
</template>
