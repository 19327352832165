var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-dialog',{attrs:{"scrollable":"","persistent":"","max-width":"600"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"primary",attrs:{"dark":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":"","left":""}},[_vm._v("mdi-update")]),_c('span',[_vm._v("Uploaden")])],1)]}}]),model:{value:(_setup.dialog),callback:function ($$v) {_setup.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"mx-auto"},[_c('v-card-title',[_c('header',{staticClass:"app-welcome-message"},[_c('h1',[_vm._v("Logo uploaden")])]),_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _setup.onCancel()}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-close")])],1)],1),_c('v-card-text',[_c('v-container',{staticClass:"register-form"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"party-add-logo"}},[_vm._v("Logo toevoegen")]),_c('vue-dropzone',{ref:"partyLogoDropzone",attrs:{"id":"party-logo-dropzone","options":{
                  url: _setup.logoUploadUrl,
                  acceptedFiles: '.png,.jpg,.jpeg,.gif',
                  maxFilesize: 1,
                  maxFileSizeInMB: 3,
                  maxFiles: 1,
                  thumbnailHeight: 200,
                  addRemoveLinks: true,
                  dictDefaultMessage: 'Sleep uw bestand hier naar toe of klik om te uploaden. Toegestane formaten: .png, .jpg, .jpeg, .gif',
                  dictFileTooBig: 'Het bestand is te groot ({{filesize}}MiB). Maximale bestandsgrootte: {{maxFilesize}}MiB.',
                  dictInvalidFileType: 'Het is niet mogelijk bestanden van dit type te uploaden (toegestaan: .png, .jpg, .jpeg, .gif).',
                  dictResponseError: 'De Server reageerde met code {{statusCode}}.',
                  dictRemoveFile: 'Verwijder bestand',
                  dictMaxFilesExceeded: 'U kunt maximaal 1 bestand uploaden.',
                }},on:{"vdropzone-error":_setup.onUploadError}})],1)])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }